import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { changeReservationStatus } from '../../../store/Dashboard/Reservations/ReservationsAction.js';

const statusOptions = [
    { value: 'reservation_confirmed', label: 'Confirmed', styles: { bg: '#FEEFE5', text: '#F96300' } },
    { value: 'checked_in', label: 'Checked In', styles: { bg: '#E5F7EE', text: '#00AF53' } },
    { value: 'reserved', label: 'Reserved', styles: { bg: '#FDE8E9', text: '#EE161F' } },
];

const StatusDropdown = ({ params, onEditCellChangeCommitted }) => {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        const statusValue = params.value.toLowerCase();
        const initialStatus = statusOptions.find(option => option.value === statusValue);
        setSelectedStatus(initialStatus);
    }, [params.value]);

    const handleChange = async (selectedOption) => {
        setSelectedStatus(selectedOption);
        try {
            await dispatch(changeReservationStatus(params.id, selectedOption.value));
            onEditCellChangeCommitted({ id: params.id, field: 'status', value: selectedOption.value });
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '30px',
            height: '30px',
            padding: '0 8px',
            backgroundColor: state.isFocused ? 'lightgrey' : (selectedStatus ? selectedStatus.styles.bg : 'white'),
            color: selectedStatus ? selectedStatus.styles.text : 'black',
            borderColor: state.isFocused ? 'blue' : 'transparent',
            boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
            '&:hover': {
                borderColor: 'transparent',
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const colorStyles = statusOptions.find(option => option.value === data.value)?.styles;
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? colorStyles?.bg
                        : isFocused
                            ? '#b1cbcf'
                            : null,
                color: isDisabled ? '#ccc' : colorStyles?.text,
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: "12px"
            };
        },
        valueContainer: (provided) => ({
            ...provided,
            height: '30px',
            width: '100px',
            padding: '0 6px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            padding: '0px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: selectedStatus ? selectedStatus.styles.text : 'black',
            padding: '0 6px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: selectedStatus ? selectedStatus.styles.text : 'black',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    if (params.value.toLowerCase() === 'canceled') {
        return <div className="text-red-600 font-bold px-2">Canceled</div>;
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Select
                value={selectedStatus}
                onChange={handleChange}
                options={statusOptions}
                styles={customStyles}
                isSearchable={false}
                className="w-full rounded-md p-1"
                menuPortalTarget={document.body}
            />
        </div>
    );
};

export default StatusDropdown;

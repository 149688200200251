import axios from 'axios';
import { setLoading, updateReservationStatus, setErrorMessage } from './ReservationsSlice';

export const changeReservationStatus = (id, status) => async (dispatch) => {
    const beachId = sessionStorage.getItem('beach_id');
    dispatch(setLoading(true));
    try {
        await axios.post(`/beach/${beachId}/change-reservation-status/${id}`, { status });
        dispatch(updateReservationStatus({ id, status }));
    } catch (error) {
        console.error('Error in updating status', error);
    } finally {
        dispatch(setLoading(false));
    }
}
// export const exportExcelReservations = (date: string, filters: any, search: string) => async (dispatch: AppDispatch) => {
//     dispatch(setLoading(true));
//     try {
//         const params: any = { date };
//
//         if (filters.reservation_status) {
//             params.reservation_status = filters.reservation_status;
//         }
//         if (filters.reservation_types) {
//             params.reservation_types = filters.reservation_types;
//         }
//         if (search) {
//             params.search = search;
//         }
//
//         const beachId = sessionStorage.getItem('beach_id');
//
//         const response = await axios.post(`beach/${beachId}/export-reservations`, params, {
//             responseType: 'blob' // Set the response type to blob for file download
//         });
//         if (response && response.data) {
//             const url = window.URL.createObjectURL(new Blob([response.data]));
//
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'report.xlsx');
//             document.body.appendChild(link);
//             link.click();
//
//             // Clean up
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (error) {
//         dispatch(setErrorMessage('Error exporting Excel'));
//     } finally {
//         dispatch(setLoading(false));
//     }
// };

import React, { useState } from 'react';
import {
    DataGrid,
    GridToolbar,
    GridOverlay,
    GridColumnMenuContainer,
    GridColumnMenu
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { cancelReservation } from '../../../store/BeachAdmin/BeachAdminActions';
import { format } from 'date-fns';
import TagsCheckboxes from '../components/TagsCheckboxes';
import axios from 'axios';
import StatusDropdown from "./StatusDropdown.jsx";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-textPrimary': {
                        color: '#F96300',
                    },
                    '&.MuiButton-sizeSmall': {
                        color: '#F96300',
                    },
                    '&.css-1xfnxoa-MuiButtonBase-root-MuiButton-root': {
                        color: '#F96300',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#00414A',
                        color: '#FFFFFF',
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader': {
                        color: '#FFFFFF',
                        '&:hover .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root': {
                            color: '#FFFFFF',
                        },
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-menuIcon': {
                        display: 'flex',
                        color: '#FFFFFF',
                    },
                    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-selectedRowCount': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-selectLabel': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-input': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-displayedRows': {
                        color: '#00414A'
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: '#d1ddde',
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: '#d1ddde',
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiIconButton-sizeMedium': {
                        color: '#F96300'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: 'transparent',
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer'
                    }
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#F96300',
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: '#F96300',
                    },
                },
            },
        },
    },
});

const NoRowsOverlay = () => {
    return (
        <GridOverlay>
            <div className="text-center text-gray-500 w-full mt-2">No reservations found</div>
        </GridOverlay>
    );
};

const CustomColumnMenu = (props) => {
    return (
        <GridColumnMenuContainer>
            <GridColumnMenu {...props} />
        </GridColumnMenuContainer>
    );
};

const Table = ({ columns, rows, setRows, onEditCellChangeCommitted, pageType, tags }) => {
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [amountModalOpen, setAmountModalOpen] = useState(false);
    const [editAmount, setEditAmount] = useState('');

    const dispatch = useDispatch();

    const handleOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    // const handleModalOpen = async (params) => {
    //     setSelectedCell(params);
    //     const beachId = sessionStorage.getItem('beach_id');
    //     const userId = params.row.id;
    //
    //     if (params.field === 'tags') {
    //         try {
    //             const response = await axios.get(`/beach/${beachId}/user-info/${userId}`);
    //
    //             const userCriterias = response.data.data.criterias.map(criteria => criteria.id);
    //
    //             setSelectedTags(userCriterias);
    //         } catch (error) {
    //             console.error("Error", error);
    //         }
    //     } else {
    //         setEditValue(params.value);
    //     }
    //     setModalOpen(true);
    // };

    const fetchUserInfo = async (userId) => {
        const beachId = sessionStorage.getItem('beach_id');
        const response = await axios.get(`/beach/${beachId}/user-info/${userId}`);
        const userCriterias = response.data.data.criterias.map(criteria => criteria.id);
        return { tags: userCriterias, note: response.data.data.note };
    };
    const handleModalOpen = async (params) => {
        setSelectedCell(params);
        const userId = params.row.id;

        if (params.field === 'note' || params.field === 'tags') {
            try {
                const { tags: userCriterias, note } = await fetchUserInfo(userId);
                setSelectedTags(userCriterias);
                setEditValue(note);
            } catch (error) {
                console.error("Error", error);
            }
        }
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedCell(null);
        setEditValue('');
        setSelectedTags([]);
    };

    const getTagLabels = (tagIds) => {
        return tagIds.map(tagId => {
            const tag = tags.find(tag => tag.value === tagId);
            return tag ? tag.label : tagId;
        });
    };

    // const handleSave = async () => {
    //     if (selectedCell) {
    //         const beachId = sessionStorage.getItem('beach_id');
    //         const userId = selectedCell.row.id;
    //
    //         const updatedRows = rows.map(row => {
    //             if (row.id === selectedCell.id) {
    //                 const newValue = selectedCell.field === 'tags' ? getTagLabels(selectedTags) : editValue;
    //                 return {
    //                     ...row,
    //                     [selectedCell.field]: newValue
    //                 };
    //             }
    //             return row;
    //         });
    //
    //         setRows(updatedRows);
    //
    //         if (selectedCell.field === 'tags') {
    //             const tagData = selectedTags.map(tagId => ({id: tagId}));
    //             const postData = {
    //                 note: "",
    //                 data: tagData
    //             };
    //
    //             try {
    //                 await axios.post(`/beach/${beachId}/user-info/${userId}`, postData);
    //             } catch (error) {
    //                 console.error("Error", error);
    //             }
    //         }
    //         handleModalClose();
    //     }
    // };

    const handleSave = async () => {
        if (selectedCell) {
            const reservationId = selectedCell.row.id;
            const beachId = sessionStorage.getItem('beach_id');
            const userId = pageType === 'reservations' ? selectedCell.row?.userId : selectedCell.row.id;

            const updatedRows = rows.map(row => {
                if (row.id === selectedCell.id) {
                    if (selectedCell.field === 'amount') {
                        const amountValue = editAmount ? parseFloat(editAmount).toFixed(2) : '0';
                        const formattedAmount = `${amountValue}`;
                        return {
                            ...row,
                            amount: formattedAmount
                        };
                    } else {
                        return {
                            ...row,
                            tags: getTagLabels(selectedTags),
                            note: editValue
                        };
                    }
                }
                return row;
            });

            setRows(updatedRows);

            if (selectedCell.field === 'amount') {
                const amountValue = editAmount ? parseFloat(editAmount).toFixed(2) : '0';
                try {
                    await axios.post(`/beach/${beachId}/change-amount-paid/${reservationId}`, {amount: amountValue});
                } catch (error) {
                    console.error("Error updating amount paid:", error);
                }
            } else if (pageType === 'reservations' && selectedCell.field === 'note') {
                try {
                    await axios.post(`/beach/${beachId}/update-reservation-note/${reservationId}`, {note: editValue});
                } catch (error) {
                    console.error("Error updating reservation note:", error);
                }
            } else {
                const postData = {
                    note: editValue,
                    data: selectedTags.map(tagId => ({id: tagId}))
                };

                try {
                    await axios.post(`/beach/${beachId}/user-info/${userId}`, postData);
                } catch (error) {
                    console.error("Error updating user info:", error);
                }
            }

            handleModalClose();
        }
    };

    const handleTagChange = (tagValue) => {
        if (selectedTags.includes(tagValue)) {
            setSelectedTags(selectedTags.filter(tag => tag !== tagValue));
        } else {
            setSelectedTags([...selectedTags, tagValue]);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleDelete = async () => {
        const selectedDate = new Date();
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        await dispatch(cancelReservation(selectedRow.id, formattedDate));
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === selectedRow.id ? {...row, status: 'Canceled'} : row))
        );
        handleClose();
    };

    const editColumn = [
        ...columns.map((column) => {
            if (column.field === 'guest' || column.field === 'guestName') {
                return {
                    ...column,
                    renderCell: (params: GridRenderCellParams) => (
                        <div className="flex overflow-x-auto whitespace-nowrap py-2">
                            {params.value}
                        </div>
                    ),
                };
            }
            if (column.field === 'reservationTags') {
                return {
                    ...column,
                    renderCell: (params: GridRenderCellParams) => (
                        <div className="flex overflow-x-auto whitespace-nowrap">
                            {params.value}
                        </div>
                    ),
                };
            }
            if (column.field === 'amount') {
                return {
                    ...column,
                    renderCell: (params: GridRenderCellParams) => (
                        <div className="flex overflow-x-auto whitespace-nowrap">
                        <span className="py-2 cursor-pointer text-[#F96300] items-center"
                              onClick={() => handleModalOpen(params)}>
                            {`${parseFloat(params.value).toFixed(2)}`}
                        </span>
                        </div>
                    ),
                };
            }
            return {
                ...column,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.field === 'status') {
                        return <StatusDropdown params={params} onEditCellChangeCommitted={onEditCellChangeCommitted}/>;
                    }
                    if (params.field === 'tags') {
                        const value = Array.isArray(params.value) ? params.value.join(', ') : params.value;
                        return (
                            <div className="flex overflow-x-auto whitespace-nowrap">
                                {value ? (
                                    <span className="py-2 cursor-pointer text-[#F96300] items-center"
                                          onClick={() => handleModalOpen(params)}>
                                    {value}
                                </span>
                                ) : (
                                    <button type="button"
                                            className="text-primary-dark-green text-center underline text-sm"
                                            onClick={() => handleModalOpen(params)}>
                                        Add Tag
                                    </button>
                                )}
                            </div>
                        );
                    }
                    if (params.field === 'note') {
                        const value = Array.isArray(params.value) ? params.value.join(', ') : params.value;
                        return (
                            <div className="flex overflow-x-auto whitespace-nowrap">
                                {value ? (
                                    <span className="py-2 cursor-pointer text-[#F96300] items-center text-center"
                                          onClick={() => handleModalOpen(params)}>
                                    {value}
                                </span>
                                ) : (
                                    <button type="button"
                                            className="text-primary-dark-green text-center underline text-sm"
                                            onClick={() => handleModalOpen(params)}>
                                        Add Note
                                    </button>
                                )}
                            </div>
                        );
                    }
                    return params.value ? params.value : '-';
                },
            };
        }),
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                pageType === 'reservations' ? (
                    params.row.status.toLowerCase() !== 'canceled' && (
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon/>}
                            onClick={() => handleOpen(params.row)}
                        >
                            Cancel
                        </Button>
                    )
                ) : (
                    pageType === 'guests' && (
                        <>-</>
                    )
                )
            ),
        },
    ];
    return (
        <ThemeProvider theme={theme}>
            <div className="w-full overflow-x-auto">
                <div className="md:min-w-[500px]">
                    <DataGrid
                        rows={rows}
                        columns={editColumn}
                        components={{
                            NoRowsOverlay: NoRowsOverlay,
                            ColumnMenu: CustomColumnMenu,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            }
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        editMode="cell"
                        slots={{toolbar: GridToolbar}}
                        onEditCellChangeCommitted={onEditCellChangeCommitted}
                    />
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{...modalStyle}}>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-xl text-primary-dark-green text-center">Are you sure you want to delete your
                            reservation?</p>
                        <div className="flex justify-end gap-2 mt-4">
                            <button type="button" onClick={handleDelete}
                                    className="bg-primary-orange rounded-lg px-3 py-2 text-white cursor-pointer">Yes
                            </button>
                            <button type="button" onClick={handleClose}
                                    className="bg-primary-dark-green rounded-lg px-3 py-2 text-white">No
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{...modalStyle}}>
                    {selectedCell?.field !== 'amount' && (
                        <>
                            {pageType !== 'reservations' && (
                                <>
                                    <p className="font-bold text-primary-dark-green text-xl pb-8">Edit Tags</p>
                                    <TagsCheckboxes
                                        selectedTags={selectedTags}
                                        allTags={tags}
                                        onChange={handleTagChange}
                                    />
                                </>
                            )}
                            <p className="font-bold text-primary-dark-green text-xl mt-4">Edit Note</p>
                            <TextField
                                fullWidth
                                label="Note"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                className="mt-2"
                            />
                        </>
                    )}
                    {selectedCell?.field === 'amount' && (
                        <>
                            <p className="font-bold text-primary-dark-green text-xl mt-4">Edit Amount</p>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={editAmount}
                                onChange={(e) => setEditAmount(e.target.value)}
                                className="mt-2 w-full p-2 border rounded"
                            />
                        </>
                    )}
                    <div className="flex justify-end gap-2 mt-4">
                        <button type="button" onClick={handleSave}
                                className="bg-primary-dark-green rounded-lg px-3 py-2 text-white">Save
                        </button>
                        <button type="button" onClick={handleModalClose}
                                className="bg-primary-dark-green rounded-lg px-3 py-2 text-white">Cancel
                        </button>
                    </div>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

export default Table;

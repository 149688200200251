import Header from "../../../components/Header";
import React, {useEffect, useState} from "react";
import CartSidebar from "./CartSidebar";
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch} from "react-redux";
import {getRestaurantsMenu} from "../../../store/MenuBuilder/MenuBuilderAction";
import {useLocation} from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const Menu = () => {
    const query = useQuery();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const dispatch = useDispatch();
    const restaurantId = query.get('restaurantId');
    const beachId = query.get('beachId');
    const restaurants = JSON.parse(localStorage.getItem("selectedRestaurant"));
    const [restaurantData, setRestaurantData] = useState(restaurants);

    const filteredCategories = restaurantData?.categories.filter((category) =>
        category.items.some((item) => item.is_active === 1)
    );
    const handleAddToCart = (item, categoryImage) => {
        setIsSidebarOpen(true);
        const itemIndex = cartItems.findIndex(cartItem => cartItem.id === item.id);

        const image = item.image || categoryImage;

        if (itemIndex === -1) {
            setCartItems([...cartItems, {...item, quantity: 1, image}]);
        } else {
            const newCartItems = [...cartItems];
            newCartItems[itemIndex].quantity += 1;
            setCartItems(newCartItems);
        }
    };
    const getItemQuantity = (itemId) => {
        const item = cartItems.find(item => item.id === itemId);
        return item ? item.quantity : 0;
    };

    useEffect(() => {
        if (!restaurantData) {
            dispatch(getRestaurantsMenu(beachId || '')).then((response) => {
                if (response && response.length) {
                    const restaurant = response.find((restaurant) => restaurant.id === parseInt(restaurantId));
                    setRestaurantData(restaurant);
                    localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
                }
            });
        }
    }, [dispatch, restaurantData, restaurantId, beachId]);

    return (
        <div>
            <Header hasBackground={false}/>
            <div
                className="relative bg-no-repeat bg-cover min-h-screen pt-32 pb-10 flex justify-center"
                style={{backgroundImage: `url(/menu-bg.jpg)`}}
            >
                <div className="container mx-auto flex">
                    <div className={`flex-1 ${isSidebarOpen ? 'pr-80' : ''}`}>
                        <div className="md:flex items-center justify-between">
                            <div>
                                <p className="sm:text-xl text-white text-lg font-light uppercase mb-2">
                                    {restaurantData?.name} Menu
                                </p>
                                <p className="border-b-2 border-primary-orange w-10 mb-4"></p>
                                <p className="text-white font-light mb-6">
                                    Click on the items you want to order, then proceed to check out and get <br/>
                                    your order asap.
                                </p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="flex items-center bg-primary-orange text-white px-3 py-1 rounded-full">
                                    <img src="/svgs/call-waiter.svg" alt="Call waiter"/>
                                    <button type="button" className="text-sm">Call a Waiter</button>
                                </div>
                                <div className="flex items-center bg-primary-orange text-white px-3 py-1 rounded-full">
                                    <ShoppingCartIcon/>
                                    <button type="button" className="text-sm"
                                            onClick={() => setIsSidebarOpen(true)}>View
                                        your cart
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {filteredCategories.map((category, index) => (
                                <div className="grid grid-cols-2 mt-5" key={category.id}>
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        className={`w-full h-[350px] object-cover ${index % 2 === 1 ? 'order-last rounded-tr-2xl rounded-br-2xl' : 'order-first rounded-tl-2xl rounded-bl-2xl'}`}
                                    />
                                    <div
                                        className="bg-white/8 backdrop-blur-md rounded-tr-2xl rounded-br-2xl shadow-lg md:p-10 max-md:p-3 overflow-y-scroll max-h-[350px]">
                                        <p className="text-white text-3xl font-bold font-archivo mb-8">{category.name}</p>

                                        {category.items.filter((item) => item.is_active === 1).map((item) => (
                                            <div className="mb-6" key={item.id}>
                                                <div
                                                    className="text-white grid grid-cols-[1fr_1fr_1fr] items-center gap-4">
                                                    <div className="flex items-center gap-2">
                                                        {getItemQuantity(item.id) > 0 ? (
                                                            <button
                                                                type="button"
                                                                className="bg-primary-dark-green rounded-full text-white text-sm w-6 h-6 flex items-center justify-center"
                                                            >
                                                                <CheckIcon className="text-xs"/>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="bg-primary-orange rounded-full text-white w-6 h-6 aspect-square flex items-center justify-center"
                                                                onClick={() => handleAddToCart(item, category.image)}
                                                            >
                                                                +
                                                            </button>
                                                        )}
                                                        <p className="font-semibold text-sm">{item.name}</p>
                                                    </div>
                                                    <div className="border-b-2 border-dashed border-white"></div>
                                                    <p className="font-semibold text-lg">${item.price}</p>
                                                </div>
                                                <p className="text-sm text-gray-300 ml-5">{item.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <CartSidebar
                        isSidebarOpen={isSidebarOpen}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        setIsSidebarOpen={setIsSidebarOpen}
                    />
                </div>
            </div>
        </div>
    )
}
export default Menu;

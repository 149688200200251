import axios from "axios";
import {menuBuilderSlice} from "./MenuBuilderSlice";

export const addNewRestaurant = (beachId, formData, restaurantId = null) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            let response;
            if (restaurantId) {
                response = await axios.post(`/beach/${beachId}/restaurants/${restaurantId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                response = await axios.post(`/beach/${beachId}/restaurants`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
            dispatch(menuBuilderSlice.actions.addRestaurant(response.data.data));
            return response.data.data;
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const getRestaurants = (beachId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.get(`/beach/${beachId}/restaurants`);
            dispatch(menuBuilderSlice.actions.setRestaurants(response.data.data.restaurants));
            return response.data.data
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const deleteRestaurant = (beachId, restaurantId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            await axios.delete(`/beach/${beachId}/restaurants/${restaurantId}`);
            dispatch(menuBuilderSlice.actions.removeRestaurant(restaurantId));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const addNewCategory = (beachId, restaurantId, formData, categoryId = null) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            let response;
            if (categoryId) {
                response = await axios.post(
                    `/beach/${beachId}/restaurants/${restaurantId}/categories/${categoryId}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                response = await axios.post(
                    `/beach/${beachId}/restaurants/${restaurantId}/categories`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }
            dispatch(menuBuilderSlice.actions.addCategory(response.data.data));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : 'error'));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const getCategories = (beachId, restaurantId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.get(`/beach/${beachId}/restaurants/${restaurantId}/categories`);
            dispatch(menuBuilderSlice.actions.setCategories(response.data.data));
            return response.data.data;
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const deleteCategory = (beachId, restaurantId, categoryId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            await axios.delete(`/beach/${beachId}/restaurants/${restaurantId}/categories/${categoryId}`);
            dispatch(menuBuilderSlice.actions.removeCategory(categoryId));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};

export const getItems = (beachId, restaurantId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.get(`/beach/${beachId}/restaurants/${restaurantId}/items`);
            dispatch(menuBuilderSlice.actions.setItems(response.data.data));
            return response.data.data;
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const addNewItem = (beachId, restaurantId, formData, itemId = null) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            let response;
            if (itemId) {
                response = await axios.post(
                    `/beach/${beachId}/restaurants/${restaurantId}/items/${itemId}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                response = await axios.post(
                    `/beach/${beachId}/restaurants/${restaurantId}/items`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }
            dispatch(menuBuilderSlice.actions.addItem(response.data.data));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : 'error'));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const getItemCreationData = (beachId, restaurantId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.get(`/beach/${beachId}/restaurants/${restaurantId}/items/create`);
            const { ingredients, categories } = response.data.data;
            dispatch(menuBuilderSlice.actions.setItemCreationData({ ingredients, categories }));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : 'error'));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};

export const deleteItem = (beachId, restaurantId, itemId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            await axios.delete(`/beach/${beachId}/restaurants/${restaurantId}/items/${itemId}`);
            dispatch(menuBuilderSlice.actions.removeItem(itemId));
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};

export const activateMenu = (beachId) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.post(`/beach/${beachId}/activate-menu`);

            const menuUrl = response.data.data.menu_url;
            if (menuUrl) {
                console.log("Menu URL extracted:", menuUrl);
            } else {
                console.error("menu_url is not present in the response");
            }
            dispatch(menuBuilderSlice.actions.activateMenu(response.data.data));
            return menuUrl;
        } catch (error) {
            console.error("Error in activateMenu:", error);
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : 'error'));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const getRestaurantsMenu = (beach) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.get(`/beach-menu?beach=${beach}`);
            const restaurantData = response.data.data;
            localStorage.setItem('bag_beach_id' , restaurantData.beach_id)
            dispatch(menuBuilderSlice.actions.setRestaurants(restaurantData.data));
            return restaurantData;
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
export const placeOrder = (orderData) => {
    return async (dispatch) => {
        dispatch(menuBuilderSlice.actions.setLoading(true));
        try {
            const response = await axios.post(`/menu-order`, {
                beach_id: orderData.beachId,
                restaurant_id: orderData.restaurantId,
                payment_type: orderData.paymentType,
                seat_number: orderData.seatNumber,
                note: orderData.note,
                items: orderData.items.map(item => ({
                    item_id: item.itemId,
                    quantity: item.quantity,
                })),
            });

            if (response.data.success) {
                return response.data.data;
            }
            dispatch(menuBuilderSlice.actions.setOrderSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(menuBuilderSlice.actions.setError(error.response ? error.response.data : "error"));
        } finally {
            dispatch(menuBuilderSlice.actions.setLoading(false));
        }
    };
};
